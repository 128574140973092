import React from 'react';
import { PageProps, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import PageMenu from '../../../components/PageMenu/PageMenu';
import aboutUsMenu from '../../../data/page-menus/about-us';
import { useLocaleContext } from '../../../context/Locale';
import { LocaleTag } from '../../../../config/locales';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import Link from '../../../components/Link/Link';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';

import './style.scss';

type Props = PageProps & WithTranslation;

const CommunityCareGiving: React.FC<Props> = (props: Props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('communityCareGiving.seo.title'),
    },
    page: {
      title: t('communityCareGiving.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.CHERRY_RED,
        heroImage: {
          fileName: 'hero-image-2.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const prosperCanadaImageFluid =
    activeLocaleTag === LocaleTag.EN
      ? (data as any).imageEN.childImageSharp.fluid
      : (data as any).imageFR.childImageSharp.fluid;

  const prosperCanadaLink =
    activeLocaleTag === LocaleTag.EN
      ? 'https://prospercanada.org'
      : 'https://prosperitecanada.org/';

  return (
    <Layout options={layoutOptions} className="CommunityCareGiving">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <Trans i18nKey="communityCareGiving.body" t={t}>
              <ExternalLink href={prosperCanadaLink} />
              <Link
                to={getPathFromRouteKey(
                  RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES
                )}
              />
            </Trans>
            <ExternalLink
              href={prosperCanadaLink}
              className="CommunityCareGiving__external-link-image-wrap"
              aria-label={t(`${LocaleNameSpaceKeys.COMMON}:institutions.prosperCanada.name`)}
            >
              <Img
                fluid={prosperCanadaImageFluid}
                style={{ maxWidth: '410px' }}
                imgStyle={{ height: 'auto' }}
                alt={t(`${LocaleNameSpaceKeys.COMMON}:institutions.prosperCanada.name`)}
              />
            </ExternalLink>
          </section>
          <aside className="column column-33">
            <PageMenu menu={aboutUsMenu} />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    imageEN: file(relativePath: { eq: "prosper-canada-en.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageFR: file(relativePath: { eq: "prosper-canada-fr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.ABOUT_US)(CommunityCareGiving);
